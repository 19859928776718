/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "font-awesome/css/font-awesome.min.css"

import React from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import config from "./src/config/config.json"
import Layout from "./src/components/layout"
const pathUrl = config.backEnd
export const wrapRootElement = ({ element }) => {
  let cookie = new Cookies()
  let path = window.location.pathname
  // console.log("path", path)
  let isExists = path.includes("accesstoken")
  let emptyscope = <Layout>{element}</Layout>
  if (isExists) {
    path = path.split("/")
    path = path[1].split("=")
    document.cookie = `accesstoken=${path[1]}`
    window.location.replace("https://promos.mars.com")
  } else if (cookie.get("accesstoken") === undefined) {
    window.location.replace(pathUrl)
    return emptyscope
  } else {
    var config = {
      method: "get",
      url: "https://mars-group.okta.com/oauth2/default/v1/userinfo",
      headers: {
        Authorization: `Bearer ${cookie.get("accesstoken")}`,
      },
    }
    axios(config)
      .then(res => {
        // console.log(res)
        return <Layout>{element}</Layout>
      })
      .catch(err => {
        window.location.replace(pathUrl)
      })
  }
}
